@import './fonts.css';

/* custom scrollbar */

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8c8c8c;
}

@import './loading.scss';
@import './transitional-video.scss';
@import './spinner.scss';
@import './side-bar.scss';

body {
  // font-family: var(--chakra-fonts-body);
  // color: var(--chakra-colors-chakra-body-text);
  // background: unset !important;
  // transition-property: background-color;
  // transition-duration: var(--chakra-transition-duration-normal);
  // line-height: var(--chakra-lineHeights-base);
}

.donut-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 89px;
  height: 89px;
  border-radius: 50%;
  position: absolute;
  top: 29%;
  left: 30%;
}

::-webkit-scrollbar {
  display: none;
}

.base-div {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;

  .bg-img {
    width: 1920px;
    height: auto;

    @media (min-width: 1920px) {
      width: 100%;
    }
  }
}

.chakra-radio__label {
  color: #909090;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.logo-text {
  color: #4096ff !important;
}

.chakra-menu__menu-list {
  // height: 280px;
  width: 162px;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid var(--Neutrals-7, #494949);
  background: var(--Menu-BG-default-BG, #353535);
  button {
    height: 50px;
    padding: 20px;
  }
}

.chakra-text css-itvw0n {
  border: 1px solid red !important;
  display: none;
}

.content-wrap {
  background-image: url('../assets/images/svgs/DottedBG.svg');
}
