/* Visby CF Font Family */
@font-face {
  font-family: 'VisbyCF';
  src: url('../assets/Fonts/VisbyRegular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VisbyCF';
  src: url('../assets/Fonts/VisbyMedium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VisbyCF';
  src: url('../assets/Fonts/VisbyBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
